import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import PatientBox from './PatientBox';
import Overview from './overview/Overview';
import { translations } from '../helpers/translations';
import ManageSensors from './ManageSensors';
import ManageTablets from './ManageTablets';
import SessionList from './SessionList';
import { LanguageContext } from '../contexts/LanguageContext';
import { LocationContext } from '../contexts/LocationContext';
import Documents from './Documents';

export default function Tabs(props) {
    const tl = translations[useContext(LanguageContext).language];
    const location = useContext(LocationContext).location;
    const [value, setValue] = useState('1');
    const [patients, setPatients] = useState([]);
    const [userRole, setUserRole] = useState('');

    useEffect(() => {
        async function updatePatients() {
            axios.get('/api/sessions', {
                params: {
                    status: 'running',
                    location: location
                }
            }).then(res => {
                //values for tabs start with 1, so index for patients need to always be + 2 (because overview is already 1)
                const patientTabsOffset = 2;
                res.data.sessions.forEach((p, index) => p.tabIndex = index + patientTabsOffset);
                setPatients(res.data.sessions);
            });
        }
        updatePatients();
        const intervalID = setInterval(() => {
            updatePatients();
        }, 10_000);
        return () => {
            clearInterval(intervalID);
        }
    }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        async function updateUserRole() {
            if (userRole === '') {
                const res = await axios.get('/api/settings');
                setUserRole(res.data.user.role);
            }
        }
        updateUserRole();
        const intervalID = setInterval(() => {
            updateUserRole();
        }, 10_000);
        return () => {
            clearInterval(intervalID);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    function handleChange(event, newValue) {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList variant='scrollable' onChange={handleChange}>
                        <Tab label={tl.overview} value="1" />
                        {patients.map((patient, index) => (
                            <Tab key={patient.tabIndex} label={tl.patient + " " + patient.position} value={patient.tabIndex.toString()} />
                        ))}
                        {userRole === 'admin' &&
                            <Tab label={tl.sensors} value={(patients.length + 2).toString()} />
                        }
                        {userRole === 'admin' &&
                            <Tab label={tl.tablets} value={(patients.length + 3).toString()} />
                        }
                        {userRole === 'admin' &&
                            <Tab label={tl.sessions} value={(patients.length + 4).toString()} />
                        }
                        <Tab label={tl.documents} value={(patients.length + 5).toString()} />
                    </TabList>
                </Box>
                <TabPanel value="1">
                    <Overview handleClick={handleChange} />
                </TabPanel>
                {patients.map((patient, index) => (
                    <TabPanel key={patient.tabIndex} value={patient.tabIndex.toString()}>
                        <PatientBox epilepsia_id={patient.epilepsia_id} />
                    </TabPanel>
                ))}
                {userRole === 'admin' &&
                    <TabPanel value={(patients.length + 2).toString()}>
                        <ManageSensors />
                    </TabPanel>
                }
                {userRole === 'admin' &&
                    <TabPanel value={(patients.length + 3).toString()}>
                        <ManageTablets />
                    </TabPanel>
                }
                {userRole === 'admin' &&
                    <TabPanel value={(patients.length + 4).toString()}>
                        <SessionList />
                    </TabPanel>
                }
                <TabPanel value={(patients.length + 5).toString()}>
                    <Documents />
                </TabPanel>
            </TabContext >
        </Box >
    );

}
